<template>
    <v-row class="mt-3">
        <v-col cols="12">
            <h3>Create Club Team</h3>
        </v-col>
        <v-col>
            <v-select
            :items="types"
            :value="clubType"
            label="Select Club Type"
            solo
            @input="selectClubType"
            ></v-select>

            <v-stepper v-model="steps" v-show="clubType === 'Indoor Club'">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="steps > 1"
                        editable
                        step="1"
                    >
                        Club Type
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="steps > 2"
                        editable
                        step="2"
                    >
                        Club Info
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step editable step="3">
                        Club Roster
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card
                        class="mb-6"
                        elevation="0"
                        >
                            <h3>Choose Club Type</h3>
                            <v-alert
                                color="red"
                                dense
                                type="error"
                                class="mt-4 mb-0"
                                v-if="noClubCodeType"
                            >Please select club type
                            </v-alert>
                            <v-radio-group
                                v-model="clubCodeType"
                                row
                                >
                                <v-radio
                                    label="USAV"
                                    value="USAV"
                                    @change="selectClubCodeType"
                                ></v-radio>
                                <v-radio
                                    label="AAU"
                                    value="AAU"
                                    @change="selectClubCodeType"
                                ></v-radio>
                            </v-radio-group>
                            <v-text-field v-model="clubCode" ref="clubCode" :color="noClubCode ? 'error' : ''" v-if="clubCodeType === 'USAV'"
                                label="Enter USAV Club Code"
                            ></v-text-field>
                            <v-text-field v-model="clubCode" ref="clubCode" :color="noClubCode ? 'error' : ''" v-if="clubCodeType === 'AAU'"
                                label="Enter AAU Club Code"
                            ></v-text-field>
                            <p>No club codes? <a href="" @click.prevent="steps = 2; missingClubCode()">Click here</a></p>
                        </v-card>

                        <v-btn
                        small
                        color="primary"
                        @click="addClubCode()"
                        >
                        Continue
                        </v-btn>

                        <v-btn small text @click="steps = 1">
                        Cancel
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card
                        class="mb-6"
                        elevation="0"
                        >
                            <v-row>
                                <v-col>
                                    <h3>Upload Club Logo</h3>
                                    <template>
                                    <v-file-input
                                        accept="image/*"
                                        label="Select logo"
                                    ></v-file-input>
                                    </template>
                                </v-col>
                                <v-col>
                                    <h3>Choose Club Primary Color</h3>
                            <v-color-picker
                                dot-size="20"
                                canvas-height="100"
                                swatches-max-height="100"
                            ></v-color-picker>
                                </v-col>
                                <v-col>
                                    <h3>Choose Club Secondary Color</h3>
                            <v-color-picker
                                dot-size="20"
                                canvas-height="100"
                                swatches-max-height="100"
                            ></v-color-picker>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-btn
                        small
                        color="primary"
                        @click="steps = 3"
                        >
                        Continue
                        </v-btn>

                        <v-btn small text @click="steps = 2">
                        Cancel
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <div v-if="noClubCode">
                          <span class="text-h5">Add Player</span>
                          <v-alert
                            color="red"
                            dense
                            type="error"
                            class="mt-4 mb-0"
                            v-if="newPlayerAlert"
                          >Please fill in the fields for <span v-for="(obj, i) in newPlayerErrors" :key="i">{{ obj }}<span v-if="i != Object.keys(newPlayerErrors).length - 1">, </span></span>
                          </v-alert>
                          <v-row>
                            <v-col cols="3">
                              <v-text-field
                                    v-model="newTeam.age"
                                    label="Team Age/Name *"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                    v-model="newTeam.headCoach"
                                    label="Head Coach *"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                    v-model="newTeam.assistantOne"
                                    label="Assistant Coach #1"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                    v-model="newTeam.assistantTwo"
                                    label="Assistant Coach #2"
                                ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                                <v-text-field
                                  label="First Name *"
                                  required
                                  v-model="newTeam.firstName"
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  label="Last Name *"
                                  required
                                  v-model="newTeam.lastName"
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <v-menu
                                    v-model="fromDateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        label="DOB *"
                                        readonly
                                        required
                                        :value="fromDateDisp"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      locale="en-in"
                                      v-model="newTeam.dob"
                                      no-title
                                      @input="fromDateMenu = false"
                                      :min="minDate"
                                    ></v-date-picker>
                                  </v-menu>
                              </v-col>
                              <v-col>
                                <v-autocomplete
                                  :items="['OH', 'OPP', 'S', 'MB', 'DS', 'L']"
                                  label="Position *"
                                  required
                                  multiple
                                  v-model="newTeam.position"
                                ></v-autocomplete>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  label="Number"
                                  type="number"
                                  v-model="newTeam.number"
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  label="AAU Membership"
                                  v-model="newTeam.aauMembership"
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  label="USAV Membership"
                                  v-model="newTeam.usavMembership"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          <small>* indicates required field</small>
                          <v-row>
                            <v-col>
                                <v-btn
                                    class="me-2"
                                    small
                                    color="primary"
                                    @click="savePlayer(newTeam)"
                                    >
                                    Add Player
                                    </v-btn>
                            </v-col>
                          </v-row>
                          <!-- NEEDS TO BE DRY (player row turned into it's own file) -->
                          <div v-for="(t,i) in newPlayer" :key="i" class="mt-8">
                            <h3>{{t.age}}</h3>
                            <v-data-table
                            :headers="headers"
                            :items="t.players"
                            :items-per-page="15"
                            class=""
                            >
                            <!-- ADD HEAD COACH AND ASSISTANTS TO VIEW -->
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr v-for="(item,i) in items" :key="i" class="table-row">
                                    <td class="">
                                        <span v-if="!item.edit">{{ item.firstName }}</span>
                                        <v-text-field v-else single-line :value="item.firstName"></v-text-field></td>
                                    <td class="">
                                        <span v-if="!item.edit">{{ item.lastName }}</span>
                                        <v-text-field v-else single-line :value="item.lastName"></v-text-field></td>
                                    <td class="">
                                        <span v-if="!item.edit">{{ item.dob }}</span>
                                        <v-menu v-else
                                        v-model="fromDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                            :value="item.dob"
                                            v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            locale="en-in"
                                            v-model="item.dob"
                                            :value="item.dob"
                                            no-title
                                            @input="fromDateMenu = false"
                                            :min="minDate"
                                        ></v-date-picker>
                                        </v-menu>
                                    </td>
                                    <td class="">
                                        <div v-if="!item.edit">
                                        <span v-for="( pos, i ) in item.position" :key="i">{{ pos }} </span>
                                        </div>
                                        <v-autocomplete v-else
                                            :items="['OH', 'OPP', 'S', 'MB', 'DS', 'L']"
                                            multiple
                                            v-model="item.position"
                                            ></v-autocomplete>
                                    </td>
                                    <td class="">
                                        <span v-if="!item.edit">{{ item.number }}</span>
                                        <v-text-field v-else single-line :value="item.number" type="number"></v-text-field>
                                    </td>
                                    <td class="">{{ item.aauMembership }}
                                        <span class="ms-1" v-if="item.aauStatus"><i class="fas fa-check-circle green--text"></i></span>
                                        <span class="ms-1" v-else>
                                        <v-btn elevation="0" x-small rounded color="amber"><i class="fas fa-exclamation-triangle me-1"></i>Expired</v-btn>
                                        </span>
                                    </td>
                                    <td class="">{{ item.usavMembership }}
                                        <span class="ms-1" v-if="item.usavStatus"><i class="fas fa-check-circle green--text"></i></span>
                                        <span class="ms-1" v-else>
                                        <v-btn elevation="0" x-small rounded color="amber"><i class="fas fa-exclamation-triangle me-1"></i>Expired</v-btn>
                                        </span>
                                    </td>
                                    <td class="">
                                        <v-menu v-if="!item.edit" offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn elevation="0" large text v-bind="attrs" v-on="on" class="p-0"><i class="fas fa-ellipsis-h color2--text"></i></v-btn>
                                        </template>
                                        <v-list class="py-0">
                                            <v-list-item
                                            v-for="( a, index ) in actions"
                                            :key="index"
                                            >
                                            <v-list-item-title v-if="a === 'Edit'" class="text-button" @click="editField(team.age, i)"><i class="fas fa-pencil me-2"></i>{{ a }}</v-list-item-title>
                                            <v-list-item-title v-else-if="a === 'Move'" class="text-button"><i class="fas fa-arrows-v me-2"></i>{{ a }}</v-list-item-title>
                                            <v-list-item-title v-else class="text-button"><i class="fas fa-trash me-2"></i>{{ a }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        </v-menu>
                                        <v-btn-toggle v-else background-color="white">
                                        <v-btn small>
                                            <i class="fas fa-times-circle red--text" @click="cancelPlayerUpdate(team.age, i)"></i>
                                        </v-btn>
                                        <v-btn small>
                                            <i class="fas fa-save green--text" @click="updatePlayer(team.age, item, i)"></i>
                                        </v-btn>
                                        </v-btn-toggle>
                                    </td>
                                    </tr>
                                </tbody>
                            </template>
                            </v-data-table>
                            <v-btn
                                    small
                                    color="secondary"
                                    @click="addTeamToClub()"
                                    >
                                    Add Team to Club
                                    </v-btn>
                          </div>
                        </div>

                        <div v-if="!noClubCode">
                          <div v-for="(t,i) in teams" :key="i">
                            <h3>{{t.age}}</h3>
                            <v-data-table
                            :headers="headers"
                            :items="t.players"
                            :items-per-page="15"
                            class=""
                            >
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr v-for="(item,i) in items" :key="i" class="table-row">
                                    <td class="">
                                        <span v-if="!item.edit">{{ item.firstName }}</span>
                                        <v-text-field v-else single-line :value="item.firstName"></v-text-field></td>
                                    <td class="">
                                        <span v-if="!item.edit">{{ item.lastName }}</span>
                                        <v-text-field v-else single-line :value="item.lastName"></v-text-field></td>
                                    <td class="">
                                        <span v-if="!item.edit">{{ item.dob }}</span>
                                        <v-menu v-else
                                        v-model="fromDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                            :value="item.dob"
                                            v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            locale="en-in"
                                            v-model="item.dob"
                                            :value="item.dob"
                                            no-title
                                            @input="fromDateMenu = false"
                                            :min="minDate"
                                        ></v-date-picker>
                                        </v-menu>
                                    </td>
                                    <td class="">
                                        <div v-if="!item.edit">
                                        <span v-for="( pos, i ) in item.position" :key="i">{{ pos }} </span>
                                        </div>
                                        <v-autocomplete v-else
                                            :items="['OH', 'OPP', 'S', 'MB', 'DS', 'L']"
                                            multiple
                                            v-model="item.position"
                                            ></v-autocomplete>
                                    </td>
                                    <td class="">
                                        <span v-if="!item.edit">{{ item.number }}</span>
                                        <v-text-field v-else single-line :value="item.number" type="number"></v-text-field>
                                    </td>
                                    <td class="">{{ item.aauMembership }}
                                        <span class="ms-1" v-if="item.aauStatus"><i class="fas fa-check-circle green--text"></i></span>
                                        <span class="ms-1" v-else>
                                        <v-btn elevation="0" x-small rounded color="amber"><i class="fas fa-exclamation-triangle me-1"></i>Expired</v-btn>
                                        </span>
                                    </td>
                                    <td class="">{{ item.usavMembership }}
                                        <span class="ms-1" v-if="item.usavStatus"><i class="fas fa-check-circle green--text"></i></span>
                                        <span class="ms-1" v-else>
                                        <v-btn elevation="0" x-small rounded color="amber"><i class="fas fa-exclamation-triangle me-1"></i>Expired</v-btn>
                                        </span>
                                    </td>
                                    <td class="">
                                        <v-menu v-if="!item.edit" offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn elevation="0" large text v-bind="attrs" v-on="on" class="p-0"><i class="fas fa-ellipsis-h color2--text"></i></v-btn>
                                        </template>
                                        <v-list class="py-0">
                                            <v-list-item
                                            v-for="( a, index ) in actions"
                                            :key="index"
                                            >
                                            <v-list-item-title v-if="a === 'Edit'" class="text-button" @click="editField(team.age, i)"><i class="fas fa-pencil me-2"></i>{{ a }}</v-list-item-title>
                                            <v-list-item-title v-else-if="a === 'Move'" class="text-button"><i class="fas fa-arrows-v me-2"></i>{{ a }}</v-list-item-title>
                                            <v-list-item-title v-else class="text-button"><i class="fas fa-trash me-2"></i>{{ a }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        </v-menu>
                                        <v-btn-toggle v-else background-color="white">
                                        <v-btn small>
                                            <i class="fas fa-times-circle red--text" @click="cancelPlayerUpdate(team.age, i)"></i>
                                        </v-btn>
                                        <v-btn small>
                                            <i class="fas fa-save green--text" @click="updatePlayer(team.age, item, i)"></i>
                                        </v-btn>
                                        </v-btn-toggle>
                                    </td>
                                    </tr>
                                </tbody>
                            </template>
                            </v-data-table>
                          </div>
                        </div>

                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>

        </v-col>
    </v-row>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  props: [],
  data () {
    return {
      steps: 1,
      clubType: '',
      clubCodeType: '',
      clubCode: '',
      noClubCodeType: false,
      noClubCode: false,
      addPlayer: false,
      fromDateMenu: false,
      fromDateVal: null,
      minDate: '1999-01-01',
      maxDate: '2015-01-01',
      newTeam: [],
      newPlayer: [],
      newPlayerAlert: false,
      newPlayerErrors: [],
      types: [
        'Beach Club',
        'Indoor Club'
      ],
      actions: [
        'Edit', 'Move', 'Delete'
      ],
      headers: [
        { text: 'First Name', value: 'firstName', class: 'indigo lighten-4' },
        { text: 'Last Name', value: 'lastName', class: 'indigo lighten-4' },
        { text: 'DOB', value: 'dob', class: 'indigo lighten-4' },
        { text: 'Position', value: 'position', class: 'indigo lighten-4' },
        { text: 'Number', value: 'number', class: 'indigo lighten-4' },
        { text: 'AAU Membership', value: 'aauMembership', class: 'indigo lighten-4' },
        { text: 'USAV Membership', value: 'usavMembership', class: 'indigo lighten-4' },
        { text: 'Actions', value: 'actions', class: 'indigo lighten-4' }
      ],
      teams: [
        {
          age: 'U13-1',
          coach: '',
          assistantOne: '',
          assistantTwo: '',
          players: [
            { edit: false, firstName: 'Annika', lastName: 'Engen', position: ['OH', 'OPP'], number: 2, dob: '1999-12-14', city: 'Ashburn', state: 'VA', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Sarah', lastName: 'James', position: ['MB'], number: 10, dob: '1999-12-14', city: 'Easton', state: 'PA', aauMembership: '1029-123', aauStatus: false, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Ellen', lastName: 'Noh', position: ['S'], number: 5, dob: '1999-12-14', city: 'Oakton', state: 'VA', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Anna', lastName: 'Suto', position: ['S'], number: 6, dob: '1999-12-14', city: 'Germantown', state: 'MD', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Ijenna', lastName: 'Mere', position: ['MB'], number: 21, dob: '1999-12-14', city: 'Ellicott City', state: 'MD', aauMembership: '1029-123', aauStatus: false, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Kaelyn', lastName: 'Anson', position: ['L'], number: 8, dob: '1999-12-14', city: 'Kensington', state: 'MD', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true }
          ]
        },
        {
          age: 'U14-1',
          coach: '',
          assistantOne: '',
          assistantTwo: '',
          players: [
            { edit: false, firstName: 'Mic', lastName: 'Svenson', position: ['MB'], number: 10, dob: '1999-12-14', city: 'Ashburn', state: 'VA', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: false },
            { edit: false, firstName: 'Anna', lastName: 'Adiaconitei', position: ['DS'], number: 3, dob: '1999-12-14', city: 'Easton', state: 'PA', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Sophie', lastName: 'Galanova', position: ['OH'], number: 13, dob: '1999-12-14', city: 'Oakton', state: 'VA', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Lexi', lastName: 'Allouche', position: ['OPP'], number: 15, dob: '1999-12-14', city: 'Germantown', state: 'MD', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Lorelei', lastName: 'Morice', position: ['MB'], number: 9, dob: '1999-12-14', city: 'Ellicott City', state: 'MD', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Hannah', lastName: 'Kim', position: ['L'], number: 1, dob: '1999-12-14', city: 'Kensington', state: 'MD', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true }
          ]
        }
      ]
    }
  },
  computed: {
    fromDateDisp () {
      return this.newTeam.dob
      // format/do something with date
    }
  },
  methods: {
    selectClubType (type) {
      this.clubType = type
    },
    missingClubCode () {
      this.noClubCode = true
    },
    selectClubCodeType (type) {
      this.clubCodeType = type
      this.noClubCodeType = false
    },
    addClubCode () {
      if (!this.clubCodeType) {
        this.noClubCodeType = true
      } else if (!this.clubCode) {
        this.noClubCode = true
        this.$refs.clubCode.focus()
      } else {
        console.log(this.clubCodeType, this.clubCode)
        this.steps = 2
        this.noClubCodeType = false
        this.noClubCode = false
      }
    },
    addPlayerToTeam () {
      console.log('add player info to array')
    },
    addTeamToClub () {
      this.$router.push({ path: '/club/teams' })
    },
    savePlayer (data) {
      this.newPlayerErrors = []
      if (!data.age) {
        this.newPlayerErrors.push('Team Age/Name')
      }
      if (!data.headCoach) {
        this.newPlayerErrors.push('Head Coach')
      }
      if (!data.firstName) {
        this.newPlayerErrors.push('First Name')
      }
      if (!data.lastName) {
        this.newPlayerErrors.push('Last Name')
      }
      if (!data.dob) {
        this.newPlayerErrors.push('DOB')
      }
      if (!data.position) {
        this.newPlayerErrors.push('Position')
      }
      if (!data.position) {
        this.newPlayerErrors.push('Number')
      }
      if (this.newPlayerErrors.length > 0) {
        this.newPlayerAlert = true
        return false
      } else {
        this.newPlayerAlert = false
        this.dialog = false
      }
      const teamArr = {
        age: '',
        coach: '',
        assistantOne: '',
        assistantTwo: '',
        players: []
      }
      const arr = this.newPlayer.find((a) => {
        return a.age === data.age
      })
      if (arr) {
        arr.players.push({ firstName: data.firstName, lastName: data.lastName, dob: data.dob, position: data.position, number: data.number, aauMembership: data.aauMembership, usavMembership: data.usavMembership })
      } else {
        teamArr.age = data.age
        teamArr.coach = data.headCoach
        teamArr.assistantOne = data.assistantOne
        teamArr.assistantTwo = data.assistantTwo
        teamArr.players.push({ firstName: data.firstName, lastName: data.lastName, dob: data.dob, position: data.position, number: data.number, aauMembership: data.aauMembership, usavMembership: data.usavMembership })
        console.log(JSON.stringify(teamArr))
        this.newPlayer.push(teamArr)
        console.log(this.newPlayer)
      }
      this.resetNewPlayer()
    },
    resetNewPlayer () {
      this.newTeam.firstName = ''
      this.newTeam.lastName = ''
      this.newTeam.dob = ''
      this.newTeam.position = ''
      this.newTeam.number = ''
      this.newTeam.aauMembership = ''
      this.newTeam.usavMembership = ''
    },
    editField (age, index) {
      const team = this.teams.find(t => t.age === age)
      team.players[index].edit = true
    },
    cancelPlayerUpdate (age, index) {
      const team = this.teams.find(t => t.age === age)
      team.players[index].edit = false
    },
    updatePlayer (age, data, index) {
      const team = this.teams.find(t => t.age === age)
      const updated = team.players[index] = data
      updated.edit = false
    }
  },
  watch: {},
  mounted () {},
  components: {}
}
</script>
